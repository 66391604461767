import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, useState } from 'react';
import {
  StyledButton,
  StyledInputText,
  StyledInputTextWrapper
} from './InputText.styled';

export type InputTextProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'onChange'
> & {
  variant?: string;
  sizes?: string;
  style?: React.CSSProperties;
  isPassword?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputText = forwardRef(
  (
    {
      type,
      variant,
      sizes,
      style,
      isPassword,
      onChange,
      ...other
    }: InputTextProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);

    const handleShowPasswordClick = (
      e: React.MouseEvent<HTMLButtonElement>
    ): void => {
      e.preventDefault();
      setIsPasswordShow((prev) => !prev);
    };

    let finalType = type || 'text';

    if (isPassword) {
      finalType = isPasswordShow ? 'text' : 'password';
    }

    return (
      <StyledInputTextWrapper>
        <StyledInputText
          data-lpignore="true"
          autoComplete="do-not-autofill"
          type={finalType}
          variant={variant}
          sizes={sizes}
          // @ts-expect-error we know
          ref={ref}
          style={style}
          onChange={onChange}
          {...other}
        />
        {isPassword && (
          <StyledButton onClick={handleShowPasswordClick}>
            <FontAwesomeIcon icon={!isPasswordShow ? faEye : faEyeSlash} />
          </StyledButton>
        )}
      </StyledInputTextWrapper>
    );
  }
);

InputText.displayName = 'InputText';

export default InputText;
