import { OffersListFilter } from 'model/model-offers-list-filter';

export const LOADING_AFTER_MILLIS = 250;

export const DEFAULT_FILTER: OffersListFilter = {
  onlyActive: true,
  text: '',
  currency: '€',
  locations: [],
  remoteOptions: [],
  techs: [],
  techRoles: [],
  minSalary: '',
  offerLanguage: '',
  minLanguages: []
};

export const OPEN_ALERT_PARAM = 'open-alerts';
export const UNSUSCRIBE_ALERT_PARAM = 'unsuscribe-alert';
export const DEBOUNCE_FILTER_OFFERS_TIME_IN_MILLIS = 350;
